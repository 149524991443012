// QuickLinks.tsx
import { useSelector } from "react-redux";
import { RootState } from "rootReducer";
// Style
import { Wrapper, Container, Heading, UL, LI, A, LinkText, MobileContainer, MobileHeading } from "./QuickLinksStyles";

const QuickLinks = () => {
  // Hooks
  const { screenSize } = useSelector((state: RootState) => state.State);

  if (screenSize.width > 950) {
    return (
      <Wrapper>
        <Container>
          <Heading>Snabblänkar</Heading>
          <UL>
            <LI>
              <A href="https://logistikportalen.fmv.se/tjansterprodukter/blanketter/Sidor/start.aspx" rel="noreferrer">
                <LinkText>Blanketter & behörigheter</LinkText>
              </A>
            </LI>
            <LI>
              <A href="https://portal.fmvpubl.se/" target="_blank" rel="noreferrer">
                <LinkText>FMV Citrixportal </LinkText>
              </A>
            </LI>
            <LI>
              <A href="https://logp.fmv.se/tjansterprodukter/ils/sidor/start.aspx" target="_blank" rel="noreferrer">
                <LinkText>ILS</LinkText>
              </A>
            </LI>
            <LI>
              <A
                href="https://logistikportalen.fmv.se/kompetensutveckling/Sidor/Kurser-och-kompetensutveckling.aspx"
                rel="noreferrer"
              >
                <LinkText>Kurser & kompetensutveckling inom Försvarslogistik, ILS och FMV PLM</LinkText>
              </A>
            </LI>
            <LI>
              <A href="https://logp.fmv.se/tjansterprodukter/begrepp/" target="_blank" rel="noreferrer">
                <LinkText>Ordlista Försvarslogistik</LinkText>
              </A>
            </LI>
            <LI>
              <A href="https://logp.fmv.se/tjansterprodukter/prio/Sidor/Start.aspx" target="_blank" rel="noreferrer">
                <LinkText>PRIO på FMV</LinkText>
              </A>
            </LI>
            <LI>
              <A
                href="	
  https://logistikportalen.fmv.se/tjansterprodukter/plm/Sidor/PLM.aspx"
                rel="noreferrer"
              >
                <LinkText>Teamcenter</LinkText>
              </A>
            </LI>
            <LI>
              <A
                href="https://logp.fmv.se/tjansterprodukter/tia/Sidor/Startsidemall.aspx"
                target="_blank"
                rel="noreferrer"
              >
                <LinkText>TIA - Teknisk Information Autonom</LinkText>
              </A>
            </LI>
            <LI>
              <A
                href="https://logistikportalen.fmv.se/tjansterprodukter/TIFF/Sidor/Startsidemall.aspx/"
                rel="noreferrer"
              >
                <LinkText>Tidningen TIFF - Teknisk information för försvarsmaterieltjänsten</LinkText>
              </A>
            </LI>
            <LI>
              <A href="https://logp.fmv.se/tjansterprodukter/VSDL/Sidor/Start.aspx" target="_blank" rel="noreferrer">
                <LinkText>Ärendehanteringsverktyg VSDL (speciell behörighet krävs)</LinkText>
              </A>
            </LI>
          </UL>
        </Container>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <MobileContainer>
          <MobileHeading>Snabblänkar</MobileHeading>
          <UL>
            <LI>
              <A href="https://logistikportalen.fmv.se/tjansterprodukter/blanketter/Sidor/start.aspx" rel="noreferrer">
                <LinkText>Blanketter & behörigheter</LinkText>
              </A>
            </LI>
            <LI>
              <A href="https://portal.fmvpubl.se/" target="_blank" rel="noreferrer">
                <LinkText>FMV Citrixportal </LinkText>
              </A>
            </LI>
            <LI>
              <A
                href="https://logp.fmv.se/tjansterprodukter/ils/Sidor/ILSStartsida.aspx"
                target="_blank"
                rel="noreferrer"
              >
                <LinkText>ILS-portalen</LinkText>
              </A>
            </LI>
            <LI>
              <A
                href="https://logistikportalen.fmv.se/kompetensutveckling/Sidor/Kurser-och-kompetensutveckling.aspx"
                rel="noreferrer"
              >
                <LinkText>Kurser & kompetensutveckling inom Försvarslogistik, ILS och FMV PLM</LinkText>
              </A>
            </LI>
            <LI>
              <A href="https://logp.fmv.se/tjansterprodukter/begrepp/" target="_blank" rel="noreferrer">
                <LinkText>Ordlista Försvarslogistik</LinkText>
              </A>
            </LI>
            <LI>
              <A href="https://logp.fmv.se/tjansterprodukter/prio/Sidor/Start.aspx" target="_blank" rel="noreferrer">
                <LinkText>PRIO på FMV</LinkText>
              </A>
            </LI>
            <LI>
              <A
                href="	
  https://logistikportalen.fmv.se/tjansterprodukter/plm/Sidor/PLM.aspx"
                rel="noreferrer"
              >
                <LinkText>Teamcenter</LinkText>
              </A>
            </LI>
            <LI>
              <A
                href="https://logp.fmv.se/tjansterprodukter/tia/Sidor/Startsidemall.aspx"
                target="_blank"
                rel="noreferrer"
              >
                <LinkText>TIA - Teknisk Information Autonom</LinkText>
              </A>
            </LI>
            <LI>
              <A
                href="https://logistikportalen.fmv.se/tjansterprodukter/TIFF/Sidor/Startsidemall.aspx/"
                rel="noreferrer"
              >
                <LinkText>Tidningen TIFF - Teknisk information för försvarsmaterieltjänsten</LinkText>
              </A>
            </LI>
            <LI>
              <A href="https://logp.fmv.se/tjansterprodukter/VSDL/Sidor/Start.aspx" target="_blank" rel="noreferrer">
                <LinkText>Ärendehanteringsverktyg VSDL (speciell behörighet krävs)</LinkText>
              </A>
            </LI>
          </UL>
        </MobileContainer>
      </Wrapper>
    );
  }
};

export default QuickLinks;
